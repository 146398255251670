import React from 'react';
import { foo } from './ToSTemplate'

function ToS() {
  return (
    <div style={{ padding: '25px 0 25px 0' }}>
      <div dangerouslySetInnerHTML={{ __html: foo }} />
    </div>
  );
}

export default ToS;
