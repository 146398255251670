import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ReactComponent as AppStoreBadgeSvg } from '../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

function Home(props) {
  const onClick = () => {
     window.open('https://apps.apple.com/us/app/5thwheel-trips/id1599775319', '_self');
  };

  return (
    <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        padding: '100px 0 0 0',
        textAlign: 'center',
    }}>
        <Typography variant="h4" component="div" gutterBottom>
          Get the app!
        </Typography>
        <div style={{ cursor: 'pointer' }}>
          <AppStoreBadgeSvg onClick={onClick} />
        </div>
    </div>
  );
}

export default Home;
