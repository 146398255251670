import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Contact() {
  return (
    <Card sx={{ minWidth: 275, marginTop: 10 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Email:
        </Typography>
        <Typography variant="h5" component="div">
          support@5thwheel-labs.com
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Contact;
